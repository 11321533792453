@layer modules, ui, base;
@layer base {
  .Button_main__SViMi {
  border-radius: var(--sk-size-96);
  font-weight: var(--sk-typography-action-button-text-typography-m-font-weight);
  --sk-Tooltip-spacing-horizontal: var(--sk-space-16);
  --sk-Tooltip-spacing-vertical: var(--sk-space-12);
}

}
@layer base {
  .InsightPage_main__ywc39 {
  gap: var(--sk-space-40);
}

.InsightPage_title__MgAFO {
  margin-bottom: 0;
}

.InsightPage_experience__1wuaW {
  width: initial;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-grey-050);
}

.InsightPage_divider__4gStT {
  margin: 0;
}

}
@layer base {
  .SecondaryFilter_title__6fwaf {
  display: flex;
  gap: var(--sk-space-8);
  align-items: center;
}

.SecondaryFilter_select__DgzXL {
  margin: 0;
}

.SecondaryFilter_select__DgzXL [class*="jds-Select__control"] {
  border-radius: var(--sk-radius-8) !important;
}

.SecondaryFilter_select__DgzXL .SecondaryFilter_viewMoreButton__FsnVV {
  padding: var(--sk-space-12) var(--sk-space-16);
}

.SecondaryFilter_select__DgzXL .SecondaryFilter_viewMoreButton__FsnVV > button {
  width: 100%;
}

.SecondaryFilter_checkboxGroup__k259O {
  display: grid;
  align-items: center;
  margin: var(--sk-space-16) 0;
}

@media (min-width: 768px) {
  .SecondaryFilter_checkboxGroup__k259O {
    grid-template-columns: 1fr 1fr;
  }
}

.SecondaryFilter_checkboxGroup__choice__mvOii > label {
  width: 100%;
}

@media (max-width: 767px) {
  .SecondaryFilter_tooltip__yuZ6c {
    margin-left: auto;
  }

  .SecondaryFilter_tooltip__yuZ6c::after {
    transform: translateX(calc(-100% + var(--sk-Tooltip-arrow-border-distance)));
  }
}

@media (max-width: 379px) {
  .SecondaryFilter_tooltip__yuZ6c::after {
    max-width: calc(100vw - var(--sk-space-32) * 2);
  }
}

}
@layer base {
  .QuestionBlock_main__8z_RZ {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.QuestionBlock_title___g_2l {
  margin-top: var(--sk-space-24);
}

}
@layer base {
  .ResumeBlock_title___A59E {
  margin: var(--sk-space-24) 0 var(--sk-space-8);
}

.ResumeBlock_radio__c_vlE {
  display: flex;
  flex-direction: column;
  margin-top: var(--sk-space-16);
  gap: var(--sk-space-16);
}

.ResumeBlock_upload__s85yS {
  margin-top: var(--sk-space-16);
}

}
@layer base {
  .SearchCriteriaBlock_title__LtZZk {
  margin: var(--sk-space-24) 0 var(--sk-space-8);
}

.SearchCriteriaBlock_section__Q1nsC {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: var(--sk-space-24);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-4);
}

}
@layer base {
  .DropdownContent_main__bodyText__B7zat {
  font-size: var(--sk-typography-body-1-regular-font-size);
  font-style: normal;
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.DropdownContent_main__dropdownContent__LQQZo {
  padding: var(--sk-space-8) var(--sk-space-16);
  color: var(--sk-color-grey-500);
  font-family: var(--sk-font-families-poppins);

  text-align: center;
}

.DropdownContent_main__dropdownItem__XseAn {
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border: none;
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-poppins);
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.DropdownContent_main__dropdownItem__XseAn:hover,
.DropdownContent_main__dropdownItem__XseAn:focus,
.DropdownContent_main__dropdownItem__XseAn:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
  cursor: pointer;
}

.DropdownContent_main__dropdownAbroadOption__ytDdL {
  display: flex;
  gap: 8px;
  align-items: center;
}

.DropdownContent_main__error__kHScH {
  color: var(--sk-color-error-500);
}

.DropdownContent_main__abroadIcon__omGm0 {
  width: var(--sk-space-20);
  height: var(--sk-space-20);
}

}
@layer base {
  .RelatedPages_title__dwhLV,
.RelatedPages_subtitle__AOtIq {
  margin-bottom: var(--sk-space-24);
}

.RelatedPages_title__dwhLV:has(+ .RelatedPages_subtitle__AOtIq),
.RelatedPages_list__IHK25:has(+ .RelatedPages_subtitle__AOtIq) {
  margin-bottom: var(--sk-space-40);
}

.RelatedPages_list__IHK25 {
  --local-column-count: 1;
  display: grid;
  grid-template-columns: repeat(var(--local-column-count), 1fr);
  margin: 0;
  padding: 0;

  list-style-type: none;
  gap: var(--sk-space-16);
}

/* 480px */

@media (min-width: 30.001rem) {
  .RelatedPages_list__IHK25 {
    --local-column-count: 2;
  }
}

.RelatedPages_linkCard__eA0ka {
  display: flex;
  box-sizing: border-box;
  align-content: space-between;
  align-items: center;
  height: 100%;
}

.RelatedPages_link__P3egJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-16);
}

.RelatedPages_link__P3egJ span {
  flex: 1;
}

}
@layer base {
  .LinkCard_main__4Xd4u {
  --sk-data-display-card-body-content-gap: var(--sk-space-8);
  --sk-data-display-card-body-margin-right: var(--sk-space-64);
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  position: relative;
  padding: var(--sk-space-24);

  transition: border 100ms ease-out;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
}

.LinkCard_main__4Xd4u a {
  outline: none;
  color: inherit;
  text-decoration: none;
}

.LinkCard_main__4Xd4u a::after {
  content: "";
  position: absolute;
  /* place it above the seen filter */
  z-index: var(--z-up-in-the-current-stacking-context);
  inset: 0;
}

.LinkCard_main__4Xd4u a:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-color-grey-200) 2px solid;
}

.LinkCard_main__4Xd4u a:focus-visible::after {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);

  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

}
@layer base {
  .CardContent_main__qm4MT {
  display: flex;
  align-items: center;
}

/* Avatar */
.CardContent_avatar__JqCz3 {
  height: var(--sk-space-48);
  padding-right: var(--sk-space-8);
}

.CardContent_imgProfilePicture__Lkw_y {
  -o-object-fit: cover;
     object-fit: cover;
}

/* Texts */

.CardContent_textContent__Wwl_u {
  margin-right: auto;
}

.CardContent_secondaryText__D1rml {
  color: var(--sk-color-grey-500);
}

}
@layer base {
  .CardWrapper_main__jCbr4,
.CardWrapper_main__jCbr4:link,
.CardWrapper_main__jCbr4:visited {
  display: block;
  padding: var(--sk-space-12) var(--sk-space-16);
  border: var(--sk-space-2) solid var(--sk-color-black);
  border-radius: var(--sk-radius-8);
  color: inherit;
  text-decoration: none;
}

.CardWrapper_main__jCbr4:is(a):hover {
  background: var(--sk-color-dark-10);
}

.CardWrapper_main__jCbr4:is(a):focus-visible {
  outline: var(--sk-space-2) solid var(--sk-action-button-color-border-secondary-default-light);
  outline-offset: calc(-1 * var(--sk-space-2));
  background-color: var(--sk-action-button-color-background-secondary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-secondary-focus-light);
}

.CardWrapper_main__jCbr4:is(a):active {
  background: var(--sk-color-dark-20);
}

}
@layer base {
  .ClickableCard_trash__Obki3 {
  margin-left: var(--sk-space-32);
  color: var(--sk-color-error-500);
}

.ClickableCard_reload__JjL3B {
  margin-left: var(--sk-space-32);
}

}
@layer base {
  .CoverLetter_main____MPD {
  margin-top: var(--sk-space-24);
}

@media (min-width: 1312px) {
  .CoverLetter_main____MPD {
    margin-top: var(--sk-space-32);
  }
}

.CoverLetter_addNewFile__BTa_O {
  width: 100%;
  margin-top: var(--sk-space-8);
}

.CoverLetter_error__ja_HS {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
  text-align: center;
}

.CoverLetter_header__2gKu8 {
  margin-bottom: var(--sk-space-16);
}

.CoverLetter_cvOnlyDescription__QWQsk {
  display: flex;
  align-items: center;
  padding: var(--sk-space-16) var(--sk-space-12);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  gap: var(--sk-space-16);
}

.CoverLetter_cvOnlyDescription__icon__qJetC {
  flex-shrink: 0;
}

}
@layer base {
  .Dropdown_main__rq3Iq {
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context);
  box-sizing: border-box;
  width: 100%;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-8) 0;
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
  box-shadow: 0 13px 60px 0 rgb(54 46 63 / 10%);
}

}
@layer base {
  .AppLocationFilter_main__S79eI {
  position: relative;
}

.AppLocationFilter_dropdownMessage__tsXBH {
  padding: var(--sk-space-8) var(--sk-space-16);
  color: var(--sk-color-grey-500);
}

.AppLocationFilter_dropdownSuggestion__He8x8 {
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border: none;
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-poppins);
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.AppLocationFilter_dropdownSuggestion__He8x8:hover,
.AppLocationFilter_dropdownSuggestion__He8x8:focus,
.AppLocationFilter_dropdownSuggestion__He8x8:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
  cursor: pointer;
}

}
@layer base {
  .PhoneNumber_main__tJnS9 {
  --inputsGap: var(--sk-space-8);
  --wideCountryCodeWidth: 280px;

  display: flex;
  flex-wrap: wrap;
  gap: var(--inputsGap);
}

.PhoneNumber_labelWrapper__KN7BW {
  display: flex;
  flex: 1 0 100%;
  gap: var(--sk-space-4);
  align-items: center;
}

.PhoneNumber_label__xTRw3 {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.PhoneNumber_subLabel__96_03 {
  color: var(--sk-form-text-input-secondary-label-color-light);
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.PhoneNumber_countryCode__e6jZN {
  width: 100%;
}

.PhoneNumber_countryNumber__L4Qeo {
  flex: 1;
}

.PhoneNumber_countryNumberInner__PA1eW {
  color: var(--sk-color-grey-600);
  font-size: var(--sk-typography-body-2-regular-font-size);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.PhoneNumber_countryNumberInner__PA1eW::before {
  content: var(--dialCode); /* dialCode custom properties is set by javascript */
  display: inline-block;
  margin-right: var(--sk-space-8);
}

.PhoneNumber_main__tJnS9.js-wideContainer {
  .PhoneNumber_countryCode__e6jZN {
    width: var(--wideCountryCodeWidth);
  }

  .PhoneNumber_countryNumber__L4Qeo {
    width: calc(100% - var(--wideCountryCodeWidth) - var(--inputsGap));
  }
}

}
@layer base {
  .Form_form__34yHi {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

}
@layer base {
  .LocaleSwitcher_triggerButton__mQ0bd {
  flex-direction: row-reverse;
  outline-color: var(--sk-color-grey-100);
}

.LocaleSwitcher_dropdown__7Bjzn {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 1;
  right: 0;
  width: auto;
  max-height: 32vh;
  overflow-y: auto;
}

.LocaleSwitcher_localesList__qzkdc {
  margin: 0;
  padding: var(--sk-space-4) var(--sk-space-12);
  list-style: none;
}

.LocaleSwitcher_localeButton__Y0tJ2 {
  display: flex;
  flex-direction: row;
  gap: var(--sk-space-8);
  align-items: center;
  justify-content: flex-start;
}

}
@layer base {
  .StudiesInfoForm_main__p8Nai {
  position: relative;
}

.StudiesInfoForm_header__gDjTY {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding: var(--sk-space-40) 0;
  text-align: center;
}

.StudiesInfoForm_footer__vs0Af {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  border: none;
  background-color: var(--sk-color-white);
}

.StudiesInfoForm_footer__vs0Af button {
  width: calc(100% - calc(var(--sk-space-16) * 2));
  margin: var(--sk-space-16) 0;
}

.StudiesInfoForm_fields__6x1RY {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  margin-bottom: var(--sk-space-56);
  padding-bottom: 16vh;
}

@media (min-width: 768px) {
  .StudiesInfoForm_highlighted_choices__227L8 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .StudiesInfoForm_highlighted_choices__227L8 > :first-child {
    grid-column: 1 / 3;
  }

  .StudiesInfoForm_fields__6x1RY {
    padding-bottom: var(--sk-space-72);
  }
}

}
@layer base {
  .OnboardingModal_head__gTYLm {
  display: flex;
  position: fixed;
  z-index: calc(
    var(--z-up-in-the-current-stacking-context) + 1
  ); /* to have the head above the form fields AND above the upload file button */
  top: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  padding: var(--sk-space-16) 0;
  gap: var(--sk-space-8);
  background-color: var(--sk-color-white);
}

.OnboardingModal_localeSwitcher__tw996 {
  position: absolute;
  right: var(--sk-space-32);
}

}
@layer base {
  .Socials_main___iTsF {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Socials_item__CElhu {
  flex: 1 0 auto;
  margin: 0.5rem;
  text-align: center;
}

.Socials_icon__SS3zT {
  width: auto;
  height: 20px;
}

}
@layer base {
  .ApprovalPage_loader__K8ty_ {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.ApprovalPage_wrapper__oOn0Z {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: var(--sk-space-24);
}

.ApprovalPage_form__ZGJxt {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.ApprovalPage_block__3GtfD {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.ApprovalPage_link__oKiiq {
  font-size: var(--sk-font-size-1);
}

.ApprovalPage_checkbox__pK7Bw {
  margin-bottom: var(--sk-space-64);
}

.ApprovalPage_ctaBlock__SAFpI {
  position: absolute;
  right: var(--sk-space-24);
  bottom: var(--sk-space-24);
  left: var(--sk-space-24);
  text-align: right;
}

.ApprovalPage_button__B80CO {
  width: 100%;
}

@media (min-width: 48rem) {
  .ApprovalPage_form__ZGJxt {
    align-items: center;
    justify-content: center;
    width: 556px;
    margin: 0 auto;
    gap: var(--sk-space-64);
  }

  .ApprovalPage_block__3GtfD {
    padding: var(--sk-space-32);
    border: 2px solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-16);
  }

  .ApprovalPage_checkbox__pK7Bw {
    margin-bottom: 0;
  }

  .ApprovalPage_ctaBlock__SAFpI {
    position: static;
  }

  .ApprovalPage_button__B80CO {
    width: auto;
  }
}

}
@layer base {
  .SocialMedia_fieldsList__4lzsL {
  margin: var(--sk-space-24) 0 0;
  padding: 0;
  list-style: none;
}

.SocialMedia_fieldItem__YL8Cr {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-16);
  gap: var(--sk-space-16);
}

.SocialMedia_field__OJGgD {
  flex-grow: 1;
}

.SocialMedia_missingMediaList__XvQ14 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-8);
  margin: var(--sk-space-24) 0 0;
  padding: 0;
  list-style: none;
}

}
@layer base {
  .AdditionalProfileFields_fieldsWrapper__UGpwY {
  display: flex;
  flex-direction: column;
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-16);
}

.AdditionalProfileFields_submitButton__ikiFI {
  width: 100%;
}

}
@layer base {
  .Profile_header__mq2hM {
  margin-bottom: var(--sk-space-16);
}

}
@layer base {
  /**
 * Links
 * @see https://github.com/jobteaser/spark/blob/main/packages/library/src/components/Link/sk-Link.module.css
 */
.RichText_main__L_7T3 a {
  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.RichText_main__L_7T3 a:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.RichText_main__L_7T3 a:focus-visible {
  outline: var(--sk-navigation-link-color-border-focus-light) solid var(--sk-navigation-link-size-border-focus);
  outline-offset: 0;
}

/* Table */
.RichText_main__L_7T3 table {
  width: 100%;
  table-layout: fixed;
  word-wrap: break-word;
}

.RichText_main__L_7T3 table,
.RichText_main__L_7T3 td,
.RichText_main__L_7T3 tr {
  border-collapse: collapse;
  border: 1px solid var(--sk-color-black);
  vertical-align: top;
}

.RichText_main__L_7T3 td {
  padding: var(--sk-space-8);
}

/* HTML sized tags */
.RichText_richTextIframe__sid0P,
.RichText_richTextImg__kXSvA {
  max-width: 100%;
}

.RichText_richTextImg__kXSvA {
  border-radius: var(--sk-radius-4);
}

}
@layer base {
  .Form_instructionsTitle__2sQft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sk-space-8);
  margin-bottom: var(--sk-space-16);
}

.Form_instructionsTitle__2sQft p {
  line-break: anywhere; /* Long character chains like links break the responsive layout */
}

.Form_logo__7O5Dn {
  -o-object-fit: cover;
     object-fit: cover;
}

.Form_divider__Qo4Yn {
  margin: var(--sk-space-32) 0;
}

.Form_fields__DIlxH + .Form_fields__DIlxH {
  margin-top: var(--sk-space-16);
}

}
@layer base {
  /* HEADER styles */

.PageContent_section__7F_d9 {
  padding: var(--sk-space-16);
  border-radius: var(--sk-space-16);
  gap: var(--sk-space-8);
  background-color: var(--sk-color-grey-050);
}

.PageContent_request__cHckB {
  margin-top: var(--sk-space-24);
  margin-bottom: var(--sk-space-24);
}

.PageContent_contentContainer__NYouF {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.PageContent_badgesContainer__8k31G {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.PageContent_date__DtZC9 {
  align-self: flex-start;
  margin-bottom: var(--sk-space-8);
}

.PageContent_duration__UkeYQ {
  align-self: flex-start;
}

.PageContent_appointmentTitle__WMJEB {
  margin-top: var(--sk-space-8);
}

.PageContent_calendarPictoContainer__clOlZ {
  display: none;
}

.PageContent_callout__UzdDX {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_request__cHckB {
    display: none;
  }

  .PageContent_section__7F_d9 {
    margin-top: 92px;
    padding: var(--sk-space-40);
    border-radius: var(--sk-space-40);
    gap: var(--sk-space-10);
  }

  .PageContent_badgesContainer__8k31G {
    flex-direction: row;
  }

  .PageContent_date__DtZC9 {
    margin-right: var(--sk-space-12);
    margin-bottom: 0;
  }

  .PageContent_appointmentTitle__WMJEB {
    margin-top: var(--sk-space-16);
  }

  .PageContent_callout__UzdDX {
    margin-top: var(--sk-space-24);
    margin-bottom: var(--sk-space-40);
  }
}

@media (min-width: 768px) {
  .PageContent_section__7F_d9 {
    margin-top: 92px;
    padding: var(--sk-space-40);
    border-radius: var(--sk-space-40);
    gap: var(--sk-space-10);
  }

  .PageContent_date__DtZC9 {
    margin-right: var(--sk-space-12);
    margin-bottom: 0;
  }

  .PageContent_appointmentTitle__WMJEB {
    margin-top: var(--sk-space-16);
  }

  .PageContent_callout__UzdDX {
    margin-top: var(--sk-space-24);
    margin-bottom: var(--sk-space-40);
  }
}

@media (min-width: 1312px) {
  .PageContent_wideScreen__8ztY_ {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }

  .PageContent_calendarPictoContainer__clOlZ {
    display: block;
    position: absolute;
    top: -32px;
    right: -80px;
  }

  .PageContent_calendarPicto__xKmCE {
    -o-object-fit: cover;
       object-fit: cover;
    rotate: -32.04deg;
  }
}

/* BUTTONS styles */

.PageContent_buttons__2tsPl {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_buttons__2tsPl {
    flex-direction: row;
    justify-content: center;
    gap: var(--sk-space-16);
    padding-top: var(--sk-space-24);
    padding-bottom: var(--sk-space-24);
  }
}

}
@layer base {
  .TermsOfUse_main__aaa3I {
  margin: var(--sk-space-24) 0;
}

.TermsOfUse_main__aaa3I a {
  color: var(--sk-color-grey-900);
}

@media (min-width: 1312px) {
  .TermsOfUse_main__aaa3I {
    margin: var(--sk-space-32) 0;
  }
}

}
@layer base {
  .PageLayoutAside_aside__N6yqY {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
}

.PageLayoutAside_aside___closing__CxRdH {
  /*
    The JS relies on the `animationend` event to close the Application Flow.
    So, we need to define a no-op animation for small and medium screens.
  */
  animation: PageLayoutAside_no-op__EPd9U forwards;
}

.PageLayoutAside_aside___similarJobads__opCE_ {
  display: none;
}

@keyframes PageLayoutAside_no-op__EPd9U {
  0%,
  100% {
    transform: none;
  }
}

@media (min-width: 1312px) {
  .PageLayoutAside_aside__N6yqY {
    --application-flow-width: 31vw;
    content: "displayedAsPanel"; /* used on javascript side */
    position: absolute;
    z-index: var(--z-up-in-the-current-stacking-context);
    width: var(--application-flow-width);
    max-width: 560px;
    height: 100%;
    transform: translateX(calc(-1 * var(--sk-space-24)));
    animation: PageLayoutAside_slide-in-from-right__YfPEK 0.5s;
    inset: 0 0 0 auto;
  }

  .PageLayoutAside_aside___similarJobads__opCE_ {
    display: block;
  }

  .PageLayoutAside_aside___closing__CxRdH {
    animation: PageLayoutAside_slide-out-from-right__1V44c 0.5s;
    opacity: 0;
  }

  @keyframes PageLayoutAside_slide-in-from-right__YfPEK {
    0% {
      transform: translateX(0);
      opacity: 0;
    }

    33% {
      transform: translateX(0);
      opacity: 0;
    }

    100% {
      transform: translateX(calc(-1 * var(--sk-space-24)));
      opacity: 1;
    }
  }

  @keyframes PageLayoutAside_slide-out-from-right__1V44c {
    0% {
      transform: translateX(calc(-1 * var(--sk-space-24)));
      opacity: 1;
    }

    33% {
      transform: translateX(0);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 0;
    }
  }
}

}
@layer base {
  .LocationFilter_main__A768n {
  --icon-size: var(--sk-size-20);
  --field-padding: var(--sk-space-16);
  position: relative;
  width: 100%;
  height: 48px;
}

.LocationFilter_main--radius-enabled__jS36_ .LocationFilter_main__inputContainer__fEEjK {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.LocationFilter_main__pinIcon____nc6 {
  position: absolute;
  left: var(--field-padding);
  align-self: center;
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  color: var(--sk-color-black);
  pointer-events: none;
}

.LocationFilter_main__clearButton__cBiHs {
  position: absolute;
  right: var(--field-padding);
  align-self: center;
  width: var(--sk-size-24);
  height: var(--sk-size-24);
  padding: 0;
  border: none;
  border-radius: var(--sk-radius-8);
  outline: none;
  background-color: transparent;
  color: var(--sk-color-grey-200);
  line-height: 0;
  cursor: pointer;
}

.LocationFilter_main__clearButton__cBiHs:active,
.LocationFilter_main__clearButton__cBiHs:focus {
  background-color: var(--sk-color-grey-050);
  color: var(--sk-color-black);
}

.LocationFilter_main__clearButton__cBiHs:hover {
  color: var(--sk-color-black);
}

.LocationFilter_main__inputContainer__fEEjK {
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: 0 0 var(--sk-radius-16) var(--sk-radius-16);
  background-color: var(--sk-color-white);
}

.LocationFilter_main__input__B11T6:hover,
.LocationFilter_main__input__B11T6:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
}

.LocationFilter_main__input__B11T6:focus {
  background-color: var(--sk-color-grey-050);
  cursor: text;
}

.LocationFilter_main__input__B11T6 {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-right: calc(var(--field-padding) + var(--icon-size) + var(--sk-space-12));
  padding-left: calc(var(--field-padding) + var(--icon-size) + var(--sk-space-12));
  overflow: hidden;
  border: 0;
  outline: none;
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-space-16);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
  text-overflow: ellipsis;
}

.LocationFilter_main__input__B11T6::-moz-placeholder {
  -moz-transition: 0.2s;
  transition: 0.2s;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-space-16);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.LocationFilter_main__input__B11T6::placeholder {
  transition: 0.2s;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-space-16);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.LocationFilter_main__input__B11T6:focus::-moz-placeholder {
  opacity: 0;
}

.LocationFilter_main__input__B11T6:focus::placeholder {
  opacity: 0;
}

.LocationFilter_main__input__B11T6[type="search"]::-webkit-search-cancel-button,
.LocationFilter_main__input__B11T6[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
          appearance: none;
}

@media (min-width: 768px) {
  .LocationFilter_main__A768n {
    flex: 1;
    height: 56px;
  }

  .LocationFilter_main__inputContainer__fEEjK {
    border-radius: 0 var(--sk-radius-16) var(--sk-radius-16) 0;
  }
}

}
@layer base {
  .RadiusFilter_main__INBOe {
  --field-height: 48px;
  position: relative;
  height: var(--field-height);
}

.RadiusFilter_main__INBOe::before {
  content: "";
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 50%;
  bottom: auto;
  left: 0;
  width: 1px;
  height: 50%;
  transform: translateY(-50%);
  background: var(--sk-color-grey-100);
}

.RadiusFilter_select__bwzzz {
  height: var(--field-height);
}

.RadiusFilter_select__bwzzz span {
  font-size: var(--sk-font-size-2);
}

.RadiusFilter_select__bwzzz [class*="sk-Select_selectIcon"] {
  display: none;
}

.RadiusFilter_select__bwzzz [class*="sk-Select_dropdownContainer"] {
  height: 100%;
}

.RadiusFilter_select__bwzzz [class*="Dropdown_main__options-list-wrapper"] {
  right: 0;
  width: 140%;
}

.RadiusFilter_selectField___tMhb {
  width: auto;
  height: 100%;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.RadiusFilter_selectField___tMhb:active,
.RadiusFilter_selectField___tMhb:focus,
.RadiusFilter_selectField___tMhb:focus-visible,
.RadiusFilter_selectField___tMhb:hover {
  background-color: var(--sk-color-grey-050);
}

@media (min-width: 768px) {
  .RadiusFilter_main__INBOe {
    --field-height: 56px;
  }

  .RadiusFilter_main__INBOe .RadiusFilter_selectField___tMhb {
    border-radius: 0 var(--sk-radius-16) var(--sk-radius-16) 0 !important;
  }
}

}
@layer base {
  .AlgoliaLocationFilter_main__x2b_R {
  display: flex;
  flex: 1;
}

@media (max-width: 767px) {
  .AlgoliaLocationFilter_main__x2b_R {
    flex: 1.5;
  }
}

}
@layer base {
  .UnderlineShape_underlineShape__u_wIz {
  position: relative;
}

.UnderlineShape_underlineShape__text__hJkSu {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
}

.UnderlineShape_underlineShape__shape__sVajf {
  --underline-side-overflow: 5px;
  position: absolute;
  bottom: -3px;
  left: calc(var(--underline-side-overflow) * -1);
  width: calc(100% + calc(2 * var(--underline-side-overflow)));
  min-width: 20px;
  height: 65%;
}

}
@layer base {
  .PrimaryFilters_main__37DWR {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: transparent;
}

.PrimaryFilters_main__37DWR [class*="jds-ValueContainer"] {
  max-width: 85%;
}

@media (min-width: 768px) {
  .PrimaryFilters_main__37DWR {
    flex-direction: row;
    --field-height: 56px;
    width: 100%;
  }
}

.PrimaryFilters_primaryFilter__qT94w {
  flex: 1;
  min-width: 0;
}

.PrimaryFilters_searchInput__GKb0a {
  flex-grow: 1;
  margin: 0;
}

.PrimaryFilters_searchInputContainer__k8IoD {
  height: 100%;
  border: none;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"],
.PrimaryFilters_searchInputContainer__k8IoD {
  height: var(--field-height);
  margin: 0;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: var(--sk-radius-16) var(--sk-radius-16) 0 0 !important;
  border-color: transparent !important;
  outline: 0 !important;
  text-overflow: ellipsis;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:-moz-placeholder-shown, .PrimaryFilters_searchInputContainer__k8IoD:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:placeholder-shown,
.PrimaryFilters_searchInputContainer__k8IoD:placeholder-shown {
  text-overflow: ellipsis;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:hover,
.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:focus,
.PrimaryFilters_searchInputContainer__k8IoD:hover,
.PrimaryFilters_searchInputContainer__k8IoD:focus {
  background-color: var(--sk-color-grey-050) !important;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:focus {
  background-color: var(--sk-color-grey-050) !important;
}

.PrimaryFilters_contractFilter__pwaol {
  height: var(--field-height);
  max-height: var(--field-height);
}

.PrimaryFilters_contractFilter__pwaol > button {
  border: none;
  border-radius: 0;
}

@media (min-width: 768px) {
  .PrimaryFilters_searchInput__GKb0a [class*="jds-Input"],
  .PrimaryFilters_searchInputContainer__k8IoD {
    flex-grow: 1;
    border-radius: var(--sk-radius-16) 0 0 var(--sk-radius-16) !important;
  }

  .PrimaryFilters_searchInput__GKb0a [class*="jds-Input"] {
    border-radius: var(--sk-radius-16) 0 0 var(--sk-radius-16) !important;
  }
}

@media (min-width: 1312px) {
  .PrimaryFilters_searchInput__GKb0a {
    flex-grow: 2;
  }
}

@media (max-width: 63.999rem) {
  .PrimaryFilters_searchInput__GKb0a {
    flex-grow: 1;
  }
}

}
@layer base {
  /* Filters Layout  */
.Layout_filtersLayout__8EF_6 {
  position: relative;
  margin-top: var(--sk-space-8);
}

@media (min-width: 768px) {
  .Layout_filtersLayout__8EF_6 {
    margin-top: var(--sk-space-16);
  }
}

/* Filters Container  */
.Layout_filtersContainer__j19qC {
  display: flex;
  /*
    These position and z-index stack this element and its descendants above all these positioned elements:
    - the results count
    - the results sort
    - the "save this search" button
    - the job ad cards
  */
  position: relative;
  z-index: calc(var(--z-above-ui-kit-cards) + 1);
  flex-direction: column;
  padding: var(--sk-space-8);
  border-radius: var(--sk-radius-24);
  background-color: var(--sk-color-black);
  gap: var(--gap-size);
  --gap-size: var(--sk-space-8);
}

@media (min-width: 768px) {
  .Layout_filtersContainer__j19qC {
    --gap-size: var(--sk-space-16);
    flex-flow: column wrap;
    padding: var(--sk-space-16);
  }
}

/* Results Layout */
.Layout_resultsLayout__QR7x3 {
  padding-top: var(--sk-space-56);
  padding-bottom: var(--sk-space-32);
}

}
@layer base {
  /* Layout grid: parent */
.Layout_layout__69T2U {
  --local-row-gap: var(--sk-space-8);
  --local-column-gap: var(--sk-space-8);
  --local-horizontal-margin: var(--sk-space-16);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-right: var(--local-horizontal-margin);
  padding-left: var(--local-horizontal-margin);
  gap: var(--local-row-gap) var(--local-column-gap);
}

.Layout_layout__69T2U.Layout_variant_4cols__5GP6o,
.Layout_layout__69T2U.Layout_variant_3cols__Yn4Gi {
  grid-template-columns: 100%;
}

@media (min-width: 768px) {
  .Layout_layout__69T2U {
    --local-row-gap: var(--sk-space-16);
    --local-column-gap: var(--sk-space-16);
    --local-horizontal-margin: var(--sk-space-40);
    grid-template-columns: repeat(12, 1fr);
    max-width: calc(12 * var(--local-column-width) + 11 * var(--local-column-gap));
  }

  .Layout_layout__69T2U.Layout_variant_4cols__5GP6o,
  .Layout_layout__69T2U.Layout_variant_3cols__Yn4Gi {
    grid-template-columns: repeat(2, minmax(calc(50% - var(--local-column-gap)), 1fr));
  }
}

@media (min-width: 1312px) {
  .Layout_layout__69T2U {
    --local-column-width: 88px;
    --local-horizontal-margin: 0;
    position: relative;
    grid-template-columns: repeat(12, var(--local-column-width));
    width: 100%;
    max-width: calc(12 * var(--local-column-width) + 11 * var(--local-column-gap));
    margin-right: auto;
    margin-left: auto;
  }

  .Layout_layout__69T2U.Layout_variant_4cols__5GP6o {
    grid-template-columns: repeat(4, minmax(calc(25% - var(--local-column-gap)), 1fr));
  }

  .Layout_layout__69T2U.Layout_variant_3cols__Yn4Gi {
    grid-template-columns: repeat(3, minmax(calc(33.33% - var(--local-column-gap)), 1fr));
  }
}

/* Layout grid: children */
.Layout_layout__69T2U > .Layout_layout__child___full__30n5A,
.Layout_layout__69T2U > .Layout_layout__child___large__4qQ8n,
.Layout_layout__69T2U > .Layout_layout__child___medium__5hrkg {
  grid-column: 1 / span 4;
}

@media (min-width: 768px) {
  .Layout_layout__69T2U > .Layout_layout__child___full__30n5A {
    grid-column: 1 / span 12;
  }

  .Layout_layout__69T2U > .Layout_layout__child___large__4qQ8n {
    grid-column: 2 / span 10;
  }

  .Layout_layout__69T2U > .Layout_layout__child___medium__5hrkg {
    grid-column: 3 / span 8;
  }
}

@media (min-width: 1312px) {
  .Layout_layout__69T2U > .Layout_layout__child___large__4qQ8n {
    grid-column: 3 / span 8;
  }

  .Layout_layout__69T2U > .Layout_layout__child___medium__5hrkg {
    grid-column: 4 / span 6;
  }
}

/* Layout grid: custom children */
.Layout_layout__69T2U > .Layout_layout__child___custom__3AD_A {
  grid-column: 1 / span 4;
}

@media (min-width: 768px) {
  .Layout_layout__69T2U > .Layout_layout__child___custom__3AD_A {
    grid-column: var(--layout-child-from) / var(--layout-child-to);
  }
}

}
@layer base {
  .RatingDisplay_main__HLXwV {
  --local-icon-size: var(--sk-space-24);
  position: relative;

  margin: 0;
  overflow: hidden;
}

.RatingDisplay_main__HLXwV figcaption {
  visibility: hidden;
}

.RatingDisplay_main__HLXwV::before,
.RatingDisplay_main__HLXwV::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: currentcolor;
}

.RatingDisplay_main__HLXwV::before {
  -webkit-mask-image: var(--filled-src);
          mask-image: var(--filled-src);
  left: calc(var(--local-icon-size) * -5);
}

.RatingDisplay_main__HLXwV::after {
  -webkit-mask-image: var(--outlined-src);
          mask-image: var(--outlined-src);
}

.RatingDisplay_main__HLXwV,
.RatingDisplay_main__HLXwV::before,
.RatingDisplay_main__HLXwV::after {
  width: calc(var(--local-icon-size) * 5);
  height: var(--local-icon-size);
}

.RatingDisplay_rating__0__GrhT0 {
  color: var(--sk-color-grey-100);
}

.RatingDisplay_rating__1__VmBK_::before {
  left: calc(var(--local-icon-size) * -4);
}

.RatingDisplay_rating__1__VmBK_::after {
  left: calc(var(--local-icon-size));
}

.RatingDisplay_rating__2__3hV8a::before {
  left: calc(var(--local-icon-size) * -3);
}

.RatingDisplay_rating__2__3hV8a::after {
  left: calc(var(--local-icon-size) * 2);
}

.RatingDisplay_rating__3__X1RZ2::before {
  left: calc(var(--local-icon-size) * -2);
}

.RatingDisplay_rating__3__X1RZ2::after {
  left: calc(var(--local-icon-size) * 3);
}

.RatingDisplay_rating__4__jTgr_::before {
  left: calc(var(--local-icon-size) * -1);
}

.RatingDisplay_rating__4__jTgr_::after {
  left: calc(var(--local-icon-size) * 4);
}

.RatingDisplay_rating__5__jPvJ_::before {
  left: 0;
}

.RatingDisplay_rating__5__jPvJ_::after {
  left: calc(var(--local-icon-size) * 5);
}

}
@layer base {
  .CardBackdrop_backdrop__AcEOl {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
  animation: 0.2s ease-out 0s 1 CardBackdrop_show-backdrop__WZCIV;
  opacity: 0.2;
  background-color: var(--sk-color-black);
}

@keyframes CardBackdrop_show-backdrop__WZCIV {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

}
@layer base {
  .CardEdit_main__hH2Kf {
  display: flex;
  position: relative;
  /* to be above the backdrop */
  z-index: calc(var(--z-index-modal) + 1);
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sk-space-24);
  border: 2px solid var(--sk-color-black);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-white);
  gap: var(--sk-space-24);
}

.CardEdit_header__PAjdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.CardEdit_content__rVAAP {
  display: flex;
  gap: var(--sk-space-24);
}

.CardEdit_deleteBtn__UzK0g {
  --sk-action-button-color-background-primary-default-light: var(--sk-color-error-500);
  --sk-action-button-color-background-primary-default-dark: var(--sk-color-error-500);
  --sk-action-button-color-background-primary-hover-light: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-hover-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-active-light: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-active-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-focus-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-focus-light: var(--sk-color-error-550);
}

.CardEdit_actions__cW1dW {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-24);
}

.CardEdit_actions__cW1dW > div {
  display: flex;
  gap: var(--sk-space-16);
}

.CardEdit_actions__cW1dW .CardEdit_confirmContainer__LqzUb {
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .CardEdit_avatar__hrL_9 {
    display: none;
  }

  .CardEdit_actions__cW1dW {
    display: flex;
    flex-direction: column-reverse;
    gap: var(--sk-space-16);
  }

  .CardEdit_actions__cW1dW > div {
    width: 100%;
  }

  .CardEdit_deleteBtn__UzK0g,
  .CardEdit_confirmBtn__FJCo0 {
    width: 100%;
  }
}

}
@layer base {
  .Card_main__bj3pb {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sk-space-16) 0;
  transition: background-color 150ms ease-in-out;
  border-radius: var(--sk-radius-8);
  cursor: pointer;
}

.Card_main__bj3pb:hover,
.Card_main__bj3pb:focus-within {
  background-color: var(--sk-color-grey-050);
}

.Card_container__rw0sr {
  display: flex;
  position: relative;
  justify-content: flex-start;
  gap: var(--sk-space-16);
}

.Card_title__07JlQ {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card_subtitle__DBPfK {
  color: var(--sk-color-grey-700);
}

.Card_content__BzLck {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-24);
}

.Card_content__BzLck > div:first-child {
  height: 100%;
}

.Card_tag__NqLIJ {
  margin-top: var(--sk-space-42);
}

.Card_edit__G_8Gj {
  position: absolute;
  top: calc(var(--sk-space-8) * -1);
  right: 0;
}

.Card_footer__MdiUO {
  padding: var(--sk-space-24);
  border-top: 1px solid var(--sk-color-grey-100);
}

@media (min-width: 768px) {
  .Card_main__bj3pb {
    padding: var(--sk-space-24);
  }

  .Card_edit__G_8Gj {
    display: none;
  }

  .Card_main__bj3pb:hover .Card_edit__G_8Gj,
  .Card_main__bj3pb:focus-within .Card_edit__G_8Gj {
    display: block;
  }

  .Card_tag__NqLIJ {
    min-width: 7rem;
  }
}

@media (max-width: 767px) {
  .Card_container__rw0sr {
    padding-right: var(--sk-space-64);
  }

  .Card_content__BzLck {
    flex-direction: column;
    align-items: flex-start;
  }
}

}
@layer base {
  .Services_servicesWrapper__FoyTZ {
  margin-bottom: var(--sk-space-48);
  text-align: center;
}

.Services_servicesTitle__M1_TY {
  margin-bottom: var(--sk-space-16);
}

.Services_serviceColumnList__H7syf {
  display: grid;
  grid-gap: var(--sk-space-8);
  grid-template-columns: auto;
  margin-bottom: var(--sk-space-16);
  padding: 0;
}

.Services_serviceItem__xIyHc {
  display: flex;
  align-items: center;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-16);
  background: var(--sk-color-grey-025);
  text-align: start;
}

.Services_icon__rwL9D {
  flex-shrink: 0;
  margin-right: var(--sk-space-8);
  color: var(--sk-color-purple-500);
}

@media (min-width: 768px) {
  .Services_servicesTitle__M1_TY {
    margin-bottom: var(--sk-space-32);
  }

  .Services_serviceColumnList__H7syf {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1312px) {
  .Services_servicesWrapper__FoyTZ {
    margin-bottom: var(--sk-space-80);
  }

  .Services_servicesTitle__M1_TY {
    margin-bottom: var(--sk-space-40);
  }
}

}
@layer base {
  .Feedbacks_list__rqAq4 {
  display: flex;
  flex-direction: column;
  gap: var(--sk-size-24);
  margin-top: var(--sk-size-24);
}

.Feedbacks_feedback__Bo4UO {
  display: flex;
  flex-direction: column;
  gap: var(--sk-size-12);
}

}
@layer base {
  .PageFooter_main__vBfXx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--sk-space-56);
}

/* Main actions */
.PageFooter_mainActions__RkyOQ {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  row-gap: var(--sk-space-16);
}

.PageFooter_mainActions__button__fvepb {
  width: 100%;
}

@media (min-width: 768px) {
  .PageFooter_mainActions__RkyOQ {
    grid-auto-flow: column;
    width: auto;
    -moz-column-gap: var(--sk-space-32);
         column-gap: var(--sk-space-32);
  }

  .PageFooter_mainActions__button__fvepb {
    width: auto;
  }
}

.PageFooter_actionsForDesktop___e02Q {
  display: none;
}

@media (min-width: 768px) {
  .PageFooter_main__vBfXx {
    margin-top: var(--sk-space-80);
  }

  .PageFooter_mainActions__RkyOQ {
    display: none;
  }

  .PageFooter_actionsForDesktop___e02Q {
    display: flex;
    gap: var(--sk-space-16);
  }
}

/* the footer actions are NOT displayed on the printed version of the page */

@media print {
  .PageFooter_main__vBfXx {
    display: none;
  }
}

}
@layer base {
  .CompanyInfo_wrapperLogo__Qzw7E {
  height: var(--sk-size-64);
}

.CompanyInfo_signature__x_yHr {
  padding: var(--sk-space-24) 0;
}

.CompanyInfo_signature__x_yHr > picture {
  width: var(--sk-size-64);
  height: var(--sk-size-64);
  margin-right: var(--sk-size-4);
  transition: box-shadow 300ms ease-out;
  border-radius: var(--sk-radius-8);
  box-shadow: 0 2px 12px 0 rgb(217 217 217 / 50%);
}

.CompanyInfo_signature__x_yHr > picture:hover {
  border-radius: var(--sk-radius-8);
  box-shadow: 0 2px 12px 0 rgb(217 217 217 / 100%);
}

.CompanyInfo_link__3qSmH > picture:hover {
  cursor: pointer;
}

.CompanyInfo_content__PfMOG {
  overflow: hidden; /* Usefull to apply text-overflow to his div child */
}

.CompanyInfo_description__koAY3 {
  color: var(--sk-color-grey-700);
}

.CompanyInfo_description__item___desktopOnly__hZCVT {
  display: none;
}

@media (min-width: 768px) {
  .CompanyInfo_description__koAY3 {
    overflow: hidden;
    color: var(--sk-color-grey-700);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .CompanyInfo_description__item__gGmQA {
    display: inline;
  }

  .CompanyInfo_description__item___desktopOnly__hZCVT {
    display: initial;
  }

  .CompanyInfo_description__item___withSeparator__8HlId::before {
    content: "•";
    margin: 0 var(--sk-space-4);
  }
}

}
@layer base {
  .PageHeader_main__xix2W {
  padding-bottom: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageHeader_main__xix2W {
    margin-top: var(--sk-space-40);
  }
}

.PageHeader_publicationDate__xucPI {
  padding-bottom: var(--sk-space-24);
  color: var(--sk-color-grey-600);
}

@media print {
  /* decrease spacing with candidacy details on the printed version of the page */

  .PageHeader_main__xix2W {
    padding-bottom: var(--sk-space-4);
  }
}

}
@layer base {
  /**
 Layout
*/

.PageLayout_wrapper__vpNNU {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}

/* TODO: provide a props to FoLayout which manage the background kind (grey or white) */
.PageLayout_foLayout__L4XtS {
  background-color: var(--sk-color-white);
}

.PageLayout_contentLayout__kv1BQ {
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageLayout_contentLayout__kv1BQ {
    padding-bottom: var(--sk-space-80);
  }
}

@media (min-width: 1312px) {
  .PageLayout_contentLayout__kv1BQ {
    position: relative;
    transition: transform 0.5s;
  }

  .PageLayout_contentLayout___withAside__04szM {
    transform: translateX(-208px); /* (88px * 2) + (16px * 2)  */
  }
}

}
@layer base {
  /* visual style of the slidebar when it is visible */

@media (max-width: 767px) {
  .SlideBarCandidateActions_main__FRA0V {
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    padding: var(--sk-space-16);
    background-color: var(--sk-color-white);
    /* shadow is --sk-color-grey-600 with 15% opacity */
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) 0 rgba(100 96 108 / 15%);
  }
}

@media (min-width: 768px) {
  .SlideBarCandidateActions_main__FRA0V {
    display: flex;
    position: sticky;
    bottom: 0;
    justify-content: center;
    height: 0;
  }

  .SlideBarCandidateActions_contentBox___eo6v {
    position: relative;
    /* height of the element + padding bottom to not touch the bottom of the screen */
    bottom: calc(var(--sk-space-48) + var(--sk-space-16));
  }
}

/* management of progressive appearance and disappearance of the slidebar */

.SlideBarCandidateActions_main___appearing__PydPZ {
  animation: SlideBarCandidateActions_fade-in-from-none__W2W0N 0.5s;
}

.SlideBarCandidateActions_main___disappearing___PEZI {
  animation: SlideBarCandidateActions_fade-out-to-none__cu89u 0.5s forwards;
}

@keyframes SlideBarCandidateActions_fade-in-from-none__W2W0N {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes SlideBarCandidateActions_fade-out-to-none__cu89u {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* the scroll bar should not be present on the printed version of the page */

@media print {
  .SlideBarCandidateActions_main__FRA0V {
    display: none;
  }
}

}
@layer base {
  .SchoolBadge_main__v5iU7 {
  display: inline-flex;
  align-items: center;
  height: 26px;
  border: 1px solid var(--sk-color-grey-200);
  border-radius: var(--sk-radius-8);
}

.SchoolBadge_label__wg2oB {
  padding: 0 var(--sk-space-8) 0 var(--sk-space-8);
  color: var(--sk-color-grey-700);
}

.SchoolBadge_logo__j6kwN {
  margin-left: var(--sk-space-4);
  border-radius: var(--sk-radius-4);
}

}
@layer base {
  .TopBadges_main__LS3o2 {
  margin-bottom: var(--sk-space-24);
}

.TopBadges_main__LS3o2,
.TopBadges_secondary__5zD9I {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-16) var(--sk-space-8);
}

}
@layer base {
  .Card_main__odW37 {
  padding: var(--sk-space-16) 0;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.Card_title__46mwY {
  margin-bottom: var(--sk-space-8);
}

@media (min-width: 768px) {
  .Card_main__odW37 {
    padding: var(--sk-space-16) var(--sk-space-24);
    border-radius: var(--sk-space-8);
    background: var(--sk-color-grey-050);
  }
}

}
@layer base {
  .Summary_main__DPlYe {
  margin-top: var(--sk-space-56);
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .Summary_main__DPlYe {
    display: grid;
    grid-auto-columns: 1fr;
    gap: var(--sk-space-24);
    margin-top: var(--sk-space-80);
  }

  .Summary_item__T15UW {
    grid-row: 1;
  }
}

}
@layer base {
  .TagsSection_main__MBqRI {
  display: flex;
  flex-wrap: wrap;
  margin: 0; /* reset browsers style */
  padding: 0; /* reset browsers style */
  list-style: none; /* reset browsers style */
  gap: var(--sk-space-8);
}

}
@layer base {
  .PrivateOfferButton_main__AbTB_ {
  width: 100%;
}

}
@layer base {
  /* Aside */
.Aside_aside__wPMHD {
  display: none;
}

.Aside_aside___isPrefilteredJobSearchPage__Nr5xF,
.Aside_aside___isRssFeedVisible__1NU_a,
.Aside_aside___isPrivateOfferButtonVisible__n1EdD {
  display: initial;
}

.Aside_aside___hasNotSavedSearches__M76LO {
  padding-top: calc(var(--sk-space-48) + var(--sk-space-12));
}

.Aside_aside__divider__mIdhH {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-32);
}

.Aside_aside__divider__mIdhH:first-child {
  display: none;
}

@media (max-width: 767px) {
  .Aside_aside__wPMHD {
    margin-top: var(--sk-space-32);
  }

  .Aside_aside__wPMHD .Aside_savedSearches__jZgAi,
  .Aside_aside__wPMHD .Aside_savedSearches__jZgAi + .Aside_aside__divider__mIdhH {
    display: none;
  }
}

@media (min-width: 768px) {
  .Aside_aside__wPMHD {
    display: initial;
  }
}

}
@layer base {
  .PageContent_main___O7aK {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--sk-space-32);
  background-color: var(--sk-color-grey-025);
}

.PageContent_primaryFilters__j52b2 {
  /*
    With this z-index, this flex item will be above the secondary filters
    that are positioned and after in the order of appearance in the HTML.
    current stacking context : filtersContainer
  */
  z-index: var(--z-up-in-the-current-stacking-context);
}

.PageContent_results__zSSNO {
  display: flex;
  flex-direction: column;
  margin: var(--sk-space-12) 0 var(--sk-space-32);
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-16);
}

.PageContent_saveSearchButton__l2KC6 {
  display: flex;
  position: sticky;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  bottom: var(--sk-space-24);
  left: 0;
  justify-content: center;
  width: 100%;
  margin-top: var(--sk-space-32);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .PageContent_saveSearchButton__l2KC6 {
    width: auto;
  }

  .PageContent_saveSearchButton___isSaveSearchButtonInSearchBarEnabled__jeqAl {
    display: none;
  }

  .PageContent_illustration__2V73p {
    z-index: var(--z-up-in-the-current-stacking-context) !important;
    top: var(--sk-space-64) !important;
    right: var(--sk-space-40) !important;
    transform: rotate(0deg) !important;
  }
}

@media (min-width: 1312px) {
  .PageContent_illustration__2V73p {
    top: var(--sk-space-16) !important;
    right: var(--sk-space-24) !important;
  }
}

}
@layer base {
  .PageContent_main__J2sE4 {
  flex: 1;
  padding: var(--sk-space-24) 0;
  background-color: var(--sk-color-grey-025);
}

.PageContent_content__yOjQe {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  padding: var(--sk-space-24);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
}

.PageContent_notice__TthIP {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  color: var(--sk-color-grey-800);
  gap: var(--sk-space-16);
}

.PageContent_notice__TthIP .PageContent_icon__i4wO_ {
  color: var(--sk-color-purple-500);
}

.PageContent_notice__TthIP span {
  flex: 1;
}

}
@layer base {
  .NewPageContent_main__mxh1l {
  display: flex;
  grid-column: 1 / 5;
  flex-direction: column;
  gap: var(--sk-space-40);
  margin-top: var(--sk-space-16);
}

@media (min-width: 768px) {
  .NewPageContent_main__mxh1l {
    grid-column: 1 / -1;
    margin-top: var(--sk-space-56);
  }
}

}
@layer base {
  .PageContent_main__lBKFk {
  display: flex;
  grid-column: 1 / 5;
  flex-direction: column;
  gap: var(--sk-space-40);
}

.PageContent_layout__iLeNN {
  --local-row-gap: var(--sk-space-40) !important;
}

.PageContent_rightColumn__k6dYe {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-48);
}

.PageContent_section__QFTta {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.PageContent_headingContainer__K3myO {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageContent_block__QEM_k {
  height: 300px;
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-green-200);
}

@media (min-width: 768px) {
  .PageContent_main__lBKFk {
    grid-column: 1 / 9;
    padding-right: var(--sk-space-12);
  }

  .PageContent_layout__iLeNN {
    --local-row-gap: var(--sk-space-80) !important;
  }

  .PageContent_newDashboardMain__nnMXk {
    grid-column: 1 / -1;
  }
}

@media (min-width: 1312px) {
  .PageContent_rightColumn__k6dYe {
    margin-left: calc(var(--sk-space-72) - var(--local-column-gap));
  }
}

@media (min-width: 80rem) {
  .PageContent_main__lBKFk {
    grid-column: 4 / 10;
    padding: 0 var(--sk-space-24);
  }

  .PageContent_rightColumn__k6dYe {
    margin-left: var(--sk-space-56);
  }
}

@media (max-width: 767px) {
  .PageContent_layout__iLeNN > :first-child {
    order: 1;
  }

  .PageContent_layout__iLeNN > :nth-child(2) {
    order: 3;
  }

  .PageContent_layout__iLeNN > :last-child {
    order: 2;
  }
}

}
@layer base {
  .Pagination_main__J10Ng {
  display: flex;
  justify-content: center;
}

.Pagination_item__Raak6 {
  box-sizing: border-box;
  min-width: calc(var(--sk-size-40) + var(--sk-size-4));
  min-height: calc(var(--sk-size-40) + var(--sk-size-4));
  padding: 0;
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  text-align: center;
}

.Pagination_item___ellipsis__aWI89 {
  min-width: inherit;
  margin: 0 var(--sk-size-4);
  padding-top: var(--sk-size-8);
}

.Pagination_item___current__V_VJj {
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
  pointer-events: none;
}

@media (max-width: 409px) {
  .Pagination_item___ellipsis__aWI89 ~ .Pagination_item___current_close_sibling__RY3Xf:not(.Pagination_item___first__65cnq, .Pagination_item___last__feZeg) {
    display: none;
  }
}

@media (max-width: 767px) {
  .Pagination_item___current_sibling__aq2dX:not(.Pagination_item___ellipsis__aWI89, .Pagination_item___current_close_sibling__RY3Xf, .Pagination_item___first__65cnq, .Pagination_item___last__feZeg) {
    display: none;
  }
}

@media (min-width: 768px) {
  .Pagination_item___mobile_ellipsis__LrXst {
    display: none;
  }
}

}
@layer base {
  .Layout_main__a82xA {
  --local-row-gap: var(--sk-space-16);

  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-size-40);
}

.Layout_main__a82xA.Layout_main__primary__1Ze0f:has(+ .Layout_main__a82xA) {
  padding-bottom: 0;
}

.Layout_main__a82xA.Layout_main__primary__1Ze0f + .Layout_main__a82xA {
  padding-top: var(--sk-space-40);
}

.Layout_main__a82xA.Layout_main__secondary__B2mXw + .Layout_main__a82xA {
  padding-top: var(--sk-space-32);
}

.Layout_main__a82xA.Layout_main__secondary__B2mXw {
  position: relative;
}

.Layout_main__a82xA.Layout_main__secondary__B2mXw::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context);
  top: 0;
  bottom: 0;
  width: 100vw;
  margin-left: 50%;
  background-color: var(--sk-color-black);
  translate: -50%;
}

@media (min-width: 768px) {
  .Layout_main__a82xA {
    --local-row-gap: var(--sk-space-24);

    padding-top: var(--sk-size-88);
    padding-bottom: var(--sk-size-88);
  }

  .Layout_main__a82xA.Layout_main__primary__1Ze0f + .Layout_main__a82xA {
    padding-top: var(--sk-size-56);
  }

  .Layout_main__a82xA.Layout_main__secondary__B2mXw + .Layout_main__a82xA {
    padding-top: var(--sk-space-64);
  }
}

.Layout_head__PKPe0 {
  display: flex;
  flex-direction: column;
}

.Layout_subtitle__kgtiM {
  margin-top: var(--sk-space-16);
}

.Layout_description__GlowJ {
  margin-top: var(--sk-space-8);
}

.Layout_illustration__eOIWS {
  display: none;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

@media (min-width: 768px) {
  .Layout_illustration__eOIWS {
    display: block;
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context);
    top: var(--sk-size-32);
    right: 0;
  }
}

.Layout_filters__UY09H {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  flex-direction: column;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-24);
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  gap: var(--sk-space-16);
}

.Layout_results__vb5JA {
  display: flex;
  position: relative;
  z-index: var(--z-reset-in-the-current-stacking-context);
  flex-direction: column;
  gap: var(--sk-space-24);
}

.Layout_results--loading__blspS {
  animation: Layout_results-loading__NxFnP 1s linear infinite alternate;
  color: currentcolor;
}

@keyframes Layout_results-loading__NxFnP {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

}
@layer base {
  .Pagination_main--loading__q7C11 {
  cursor: not-allowed;
}

.Pagination_main--loading__q7C11 a,
.Pagination_main--loading__q7C11 button {
  pointer-events: none;
}

}
@layer base {
  .State_main__aJBNo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 364px;
  margin-right: auto;
  margin-left: auto;
  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-space-40);
}

.State_icon__vNRht {
  width: var(--sk-size-104);
  height: var(--sk-size-104);
}

.State_title__azYHv,
.State_description__hu2ph {
  text-align: center;
}

.State_title__azYHv {
  margin-top: var(--sk-space-8);
}

.State_description__hu2ph {
  margin-top: var(--sk-space-8);
}

}
@layer base {
  /* LAYOUT */

@media (min-width: 768px) {
  .PageContent_illustration__XFs3W {
    display: none;
  }
}

@media (min-width: 1312px) {
  .PageContent_illustration__XFs3W {
    display: block;
    margin-top: var(--sk-space-24);
    transform: rotate(15deg);
  }
}

/* RESULTS */
.PageContent_resultsHeader__34bSy {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageContent_resultsItems__tHkds {
  --local-horizontal-margin: 0 !important;
  --local-row-gap: var(--sk-space-24) !important;

  margin-top: 0;
  margin-bottom: var(--sk-space-16);
}

.PageContent_resultsItem__rzGHk {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.PageContent_resultsItem__rzGHk > article,
.PageContent_resultsItem__rzGHk .PageContent_card__LGXYm {
  height: 100%;
}

}
@layer base {
  .PageContent_main__LfuZ5 {
  overflow: hidden;
}

.PageContent_main__LfuZ5 + div {
  z-index: var(--z-up-in-the-current-stacking-context);
  background-color: var(--sk-color-white);
}

@media (min-width: 1312px) {
  .PageContent_main__LfuZ5 {
    overflow: visible;
  }
}

.PageContent_illustration__j0RY4 {
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context);
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  scale: 0.33;
}

.PageContent_illustration--cv__qmfDA {
  rotate: 15deg;
  top: -138px;
  left: -168px;
}

.PageContent_illustration--info__QM19D {
  rotate: 15deg;
  top: -80px;
  right: -110px;
}

.PageContent_illustration--search__bBQ9p {
  rotate: 15deg;
  bottom: -160px;
  left: 60px;
}

.PageContent_illustration--stats__HyhLe {
  right: 0;
  bottom: -40px;
  rotate: -15deg;
}

@media (min-width: 768px) {
  .PageContent_illustration__j0RY4 {
    scale: 0.5;
  }
}

@media (min-width: 1312px) {
  .PageContent_illustration__j0RY4 {
    scale: 1;
  }

  .PageContent_illustration--cv__qmfDA {
    top: -88px;
    left: -128px;
  }

  .PageContent_illustration--info__QM19D {
    top: -75px;
    right: -50px;
  }

  .PageContent_illustration--search__bBQ9p {
    bottom: -88px;
    left: 0;
  }

  .PageContent_illustration--stats__HyhLe {
    right: 90px;
    bottom: 0;
  }
}

.PageContent_headline__KmiiN {
  z-index: var(--z-up-in-the-current-stacking-context);
  padding-right: var(--sk-space-24);
  padding-left: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_headline__KmiiN {
    padding: 0;
  }
}

}
@layer base {
  .TypeCard_main__q_Jla {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: var(--sk-space-16);
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  gap: var(--sk-space-24);
  container-name: typecard;
  --icon-button-size: 44px;
  --slots-max-width: 481px; /* Fixed value set by product design decision */
}

.TypeCard_typeInfos__k7sQs {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: stretch;
  gap: var(--sk-space-16);
}

.TypeCard_seeMore__Muxr5 p {
  line-break: anywhere; /* Long character chains like links break the responsive layout */
}

.TypeCard_badge___CTWA {
  align-self: flex-start;
}

.TypeCard_slots__KhXe0 {
  flex: 0 0 auto;
  align-items: stretch;
  align-self: center;
  width: 100%;
  max-width: var(--slots-max-width);
}

@media (min-width: 768px) {
  .TypeCard_main__q_Jla {
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    padding: var(--sk-space-24) var(--sk-space-48);
    gap: var(--sk-space-32);
  }

  .TypeCard_slots__KhXe0 {
    flex: 0 0 var(--slots-max-width);
    align-self: flex-start;
  }

  .TypeCard_typeInfos__k7sQs {
    min-width: 335px; /* Fixed value set by product design decision */
  }
}

}
@layer base {
  .ExperienceViewCard_main__W_7Fw {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sk-space-16) 0;
  transition: background-color 150ms ease-in-out;
  border-radius: var(--sk-radius-8);
  cursor: pointer;
}

.ExperienceViewCard_main__W_7Fw:hover,
.ExperienceViewCard_main__W_7Fw:focus-within {
  background-color: var(--sk-color-grey-050);
}

.ExperienceViewCard_container__YTeUq {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--sk-space-16);
}

.ExperienceViewCard_header__pMYGF {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

.ExperienceViewCard_overtitle__X8Y_t {
  color: var(--sk-color-grey-700);
}

.ExperienceViewCard_titleContainer__WWVVS {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

.ExperienceViewCard_title__tG6A3 {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ExperienceViewCard_subtitle__dP4Oe {
  color: var(--sk-color-grey-700);
}

.ExperienceViewCard_content__8FJYE {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-24);
}

.ExperienceViewCard_content__8FJYE > div:first-child {
  height: 100%;
}

.ExperienceViewCard_description__XRZfH {
  margin-top: var(--sk-space-16);
  white-space: pre-wrap;
}

.ExperienceViewCard_tag__MBphB {
  margin-top: var(--sk-space-42);
}

.ExperienceViewCard_tag--desktop__BvhiP {
  display: none;
}

.ExperienceViewCard_edit__ZYQRH {
  position: absolute;
  top: calc(var(--sk-space-8) * -1);
  right: 0;
}

.ExperienceViewCard_footer__nNoqe {
  margin-top: var(--sk-space-16);
  padding: var(--sk-space-16) var(--sk-space-24);
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-12);
}

@media (min-width: 768px) {
  .ExperienceViewCard_main__W_7Fw {
    padding: var(--sk-space-24);
  }

  .ExperienceViewCard_edit__ZYQRH {
    display: none;
  }

  .ExperienceViewCard_main__W_7Fw:hover .ExperienceViewCard_edit__ZYQRH,
  .ExperienceViewCard_main__W_7Fw:focus-within .ExperienceViewCard_edit__ZYQRH {
    display: block;
  }

  .ExperienceViewCard_tag__MBphB {
    min-width: 7rem;
  }

  .ExperienceViewCard_tag--desktop__BvhiP {
    display: block;
  }

  .ExperienceViewCard_tag--mobile__mTV7x {
    display: none;
  }
}

@media (max-width: 767px) {
  .ExperienceViewCard_container__YTeUq {
    flex-direction: column;
    padding-right: var(--sk-space-64);
  }

  .ExperienceViewCard_content__8FJYE {
    flex-direction: column;
    align-items: flex-start;
  }
}

}
@layer base {
  .MainCourseViewCard_avatar__fe0XS {
  min-width: var(--sk-Avatar-size);
}

}
@layer base {
  .OtherCourseViewCard_avatar__P9A5_ {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.OtherCourseViewCard_avatar__P9A5_ img {
  -o-object-fit: contain;
     object-fit: contain;
}

}
@layer base {
  .EsadeCustomSection_main__dynif {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-16);
  padding: var(--sk-space-24);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
}

.EsadeCustomSection_button__ty_QO {
  flex-shrink: 0;
}

}
@layer base {
  .GuidanceToolCard_main__vRCFp {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
  padding: var(--sk-space-16) var(--sk-space-24);
  transition: background-color ease-in-out 150ms;
  border-radius: var(--sk-space-12);
  color: inherit;
  text-decoration: none;
}

.GuidanceToolCard_main__vRCFp:hover,
.GuidanceToolCard_main__vRCFp:focus {
  background-color: var(--sk-color-grey-050);
}

.GuidanceToolCard_content__MyaTo {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-16);
}

.GuidanceToolCard_testNameAndTime__UHs8g {
  display: flex;
  gap: var(--sk-space-16);
  align-items: center;
  margin-bottom: var(--sk-space-8);
}

.GuidanceToolCard_chevron__Lphqk {
  min-width: var(--sk-size-24);
}

@media (min-width: 768px) {
  .GuidanceToolCard_main__vRCFp {
    flex-direction: row;
  }
}

}
@layer base {
  .GuidanceToolsSection_list__CLJOM {
  margin: 0;
  padding: 0;
}

.GuidanceToolsSection_list__item__7lbvt {
  margin: 0;
  padding: var(--sk-space-16) 0;
  list-style: none;
}

.GuidanceToolsSection_list__item__7lbvt:first-child {
  padding-top: 0;
}

.GuidanceToolsSection_list__item__7lbvt:not(:last-child) {
  border-bottom: 1px solid var(--sk-color-dark-20);
}

.GuidanceToolsSection_list__item__7lbvt:last-child {
  padding-bottom: 0;
}

}
@layer base {
  .CardListEmptyState_main__9pkQS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--sk-space-16);
  max-width: 390px;
  margin-right: auto;
  margin-left: auto;
  text-wrap: balance;
}

.CardListEmptyState_description__ghyOq {
  color: var(--sk-color-grey-400);
  text-align: center;
}

}
@layer base {
  .OtherExperienceViewCard_avatar__s0sNB {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.OtherExperienceViewCard_avatar__s0sNB img {
  -o-object-fit: contain;
     object-fit: contain;
}

}
@layer base {
  .SocialMedia_main__lop6v {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--sk-space-8);
}

.SocialMedia_link___reset__RtyzF {
  color: inherit;
  text-decoration: none;
}

}
@layer base {
  .StudiesInformation_list__B24K7 {
  padding: 0;
}

.StudiesInformation_item__4OWOe {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.StudiesInformation_item__4OWOe:not(:last-child)::after {
  content: " • ";
}

}
@layer base {
  .PersonalInformationSection_main__3WhgV {
  padding: var(--sk-space-24);
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  isolation: isolate;
}

.PersonalInformationSection_editLinkWrapper__VyUgP {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  justify-content: flex-end;
}

.PersonalInformationSection_information__0Xp7x {
  margin-top: calc(-1 * var(--sk-space-48));
  text-align: center;
}

.PersonalInformationSection_profilePictureWrapper__vyZ2s {
  display: flex;
  position: relative;
  justify-content: center;
}

.PersonalInformationSection_profilePicture__aI1vB {
  -o-object-fit: cover;
     object-fit: cover;
}

.PersonalInformationSection_careerCenterLogo__ZeMcJ {
  position: absolute;
  right: 0;
  bottom: 0;
}

.PersonalInformationSection_name____sk4 {
  margin-top: var(--sk-space-20);
}

.PersonalInformationSection_studiesInformation__kktTk {
  margin-top: var(--sk-space-4);
}

.PersonalInformationSection_contactInformation__pSa0B {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-12);
}

.PersonalInformationSection_socialMedia__dJ9e6 {
  margin-top: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PersonalInformationSection_information__0Xp7x {
    margin-top: calc(-1 * var(--sk-space-32));
  }
}

}
@layer base {
  .SeeMore_wrapper__9neUF {
  display: flex;
  align-items: flex-end;
}

.SeeMore_main__YPjlq {
  --SeeMore-text-length: unset;
  display: -webkit-box;
  overflow: hidden;
  color: unset;
  -webkit-line-clamp: var(--SeeMore-text-length);
  line-clamp: var(--SeeMore-text-length);
  -webkit-box-orient: vertical;
}

.SeeMore_main--expanded__aFhk2 {
  display: block;
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.SeeMore_main--expanded__aFhk2 + .SeeMore_seeMoreButton__MIUVZ {
  display: none;
}

.SeeMore_seeMoreButton__MIUVZ {
  width: -moz-fit-content;
  width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-decoration: underline;
  cursor: pointer;
}

}
@layer base {
  .WorkExperienceViewCard_avatar__fOfEh {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.WorkExperienceViewCard_avatar__fOfEh img {
  -o-object-fit: contain;
     object-fit: contain;
}

.WorkExperienceViewCard_footer__y7VXe {
  display: none;
}

@media (min-width: 768px) {
  .WorkExperienceViewCard_footer__y7VXe {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--sk-space-16);
  }

  .WorkExperienceViewCard_footer__action__zOTFu {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

@media (min-width: 1312px) {
  .WorkExperienceViewCard_footer__y7VXe {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--sk-space-48);
  }
}

}
